<script>
import MetisMenu from "metismenujs/dist/metismenujs";


/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: [
        {
          id: 1,
          label: "menuitems.menu.text",
          isTitle: true
        },
        {
          id: 2,
          label: "Tableau de bord",
          icon: "bx-home-circle",
          link: "/dashboard",
        },
        {
          id: 3,
          label: "Gestion des alertes",
          icon: "bx-bell",
          subItems: [
            {
              id: 4,
              label: "Alertes reçues",
              link: "/receivedAlerts",
              parentId: 3
            },
            {
              id: 6,
              label: "Nouvelle alerte",
              link: "/lunchAlert",
              parentId: 3
            },
            {
              id: 7,
              label: "Alertes Envoyés",
              link: "/listAlert",
              parentId: 3
            },

          ]
        },
        {
          id: 14,
          label: "Gestion des SOS",
          icon: "bx-support",
          link: "/sosListe",
          badge: {
            variant: 'danger',
            text: "10"
          },
        },
        {
          id: 7,
          label: "Alertes Agents",
          icon: "bx-user-voice",
          subItems: [
            {
              id: 9,
              label: "Nouvelle alerte agent",
              link: "/lunchAgentsAlert",
              parentId: 7
            },
            {
              id: 10,
              label: "Liste alertes agent",
              link: "/agentAlertList",
              parentId: 7
            },
          ]
        },

        {
          id: 10,
          label: "Gestion d'interventions",
          icon: "bx-run",
          subItems: [
            {
              id: 11,
              label: "Créer une intervention",
              link: "/createIntervention",
              parentId: 10
            },
            {
              id: 12,
              label: "Liste des interventions",
              link: "/list-intervention",
              parentId: 10
            }
          ]
        },
        {
          id: 13,
          label: "Gestion d'incident",
          icon: "bx-handicap",
          subItems: [
            {
              id: 14,
              label: "Créer un incident",
              link: "/launch-incident",
              parentId: 13
            },
            {
              id: 15,
              label: "Liste des incidents",
              link: "/list-incident",
              parentId: 13
            },
            {
              id: 16,
              label: "Carte incidents",
              link: "/cartographie-incident",
              parentId: 13
            },
            {
              id: 17,
              label: "Créer Zone sensible",
              link: "/add-zone",
              parentId: 13
            },
            {
              id: 18,
              label: "Zones sensibles",
              link: "/message-incident",
              parentId: 13
            },
          ]
        },
        {
          id: 15,
          label: "Personnes recherchées",
          icon: "bx-user",
          subItems: [
            {
              id: 102,
              label: "Ajouter personne",
              link: "/add-personne",
              parentId: 15
            },
            {
              id: 103,
              label: "Liste personnes",
              link: "/liste-personne",
              parentId: 15
            },
            {
              id: 301,
              label: "Recherche Personne",
              link: "/searchPersonneInfos",
              parentId: 15
            }
          ]
        },
        {
          id: 104,
          label: "Véhicules recherchées",
          icon: "bx-car",
          subItems: [
            {
              id: 105,
              label: "Ajouter vehicule",
              link: "/add-vehicule",
              parentId: 104
            },
            {
              id: 106,
              label: "Liste vehicules",
              link: "/liste-vehicule",
              parentId: 104
            },
            {
              id: 303,
              label: "Recherche Véhicule",
              link: "/searchVehicule",
              parentId: 104
            }
          ]
        },
        {
          id: 107,
          label: "Contraventions",
          icon: "bx-table",
          subItems: [
            {
              id: 108,
              label: "Créer contravention",
              link: "/add-contravention",
              parentId: 107
            },
            {
              id: 109,
              label: "Liste contravention",
              link: "/liste-contravention",
              parentId: 107
            },{
              id: 676,
              label: "recette contravention",
              link: "/stat-contravention",
              parentId: 107
            },
            {
              id: 110,
              label: "Paramètre contravention",
              link: "/params-contravention",
              parentId: 107
            }
          ]
        },
        {
          id: 19,
          label: "Statistiques",
          icon: "bx-line-chart",
          subItems: [
            {
              id: 20,
              label: "Stats. Alertes",
              link: "/stats-alertes",
              parentId: 19
            },
            {
              id: 21,
              label: "Stats. Interventions",
              link: "/stats-interventions",
              parentId: 19
            },
            {
              id: 22,
              label: "Stats. Incidents",
              link: "/stats-incidents",
              parentId: 19
            }
          ]
        },
        {
          id: 23,
          label: "menuitems.setting.text",
          icon: "bx-cog",
          subItems: [
            {
              id: 24,
              label: "menuitems.setting.list.state-services",
              link: "/setting/state-services",
              parentId: 23
            },
            {
              id: 25,
              label: "menuitems.setting.list.treatment-center",
              link: "/setting/treatment-center",
              parentId: 23
            },
            {
              id: 26,
              label: "menuitems.setting.list.intervention-officers",
              link: "/setting/intervention-officers",
              parentId: 23
            },
            {
              id: 27,
              label: "menuitems.setting.list.treatment-officers",
              link: "/setting/treatment-officers",
              parentId: 23
            },
            {
              id: 28,
              label: "menuitems.setting.list.alert-settings.text",
              link: "/setting/alert-settings",
              parentId: 23,
              subItems: [
                {
                  id: 29,
                  label: "menuitems.setting.list.alert-settings.list.level-alerts",
                  link: "/setting/level-alert",
                  parentId: 28
                },
                {
                  id: 30,
                  label: "menuitems.setting.list.alert-settings.list.thematic-alerts",
                  link: "/setting/theme-alert",
                  parentId: 28
                },
                {
                  id: 31,
                  label: "menuitems.setting.list.alert-settings.list.status-alerts",
                  link: "/setting/statut-alert",
                  parentId: 28
                },
                {
                  id: 32,
                  label: "menuitems.setting.list.alert-settings.list.priority-alerts",
                  link: "/setting/priorite-alert",
                  parentId: 28
                },
                {
                  id: 33,
                  label: "menuitems.setting.list.alert-settings.list.type-intervention",
                  link: "/setting/intervention-alert",
                  parentId: 28
                }
              ]
            },
          ]
        },
        {
          id: 13,
          label: "menuitems.view-agent.text",
          icon: "bx-map",
          link: "/maps"
        },

      ],
      menuData: null,
    };
  },
  mounted: function () {
    if (document.getElementById("side-menu")) new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">
          {{ $t(item.label) }}
        </li>
        <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
          <a v-if="hasItems(item)" href="javascript:void(0);" class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{
              $t(item.badge.text) }}</span>
          </a>

          <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{
              $t(item.badge.text) }}</span>
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">{{ $t(subitem.label)
              }}</router-link>
              <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{
                $t(subitem.label) }}</a>
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                  <router-link :to="subSubitem.link" class="side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>