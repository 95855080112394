<script>
import Vertical from "./vertical";
import {
    layoutComputed
} from "@/state/helpers";

export default {
    components: {
        Vertical,
    },
    data() {
        return {};
    },
    
    computed: {
        ...layoutComputed,
    },
    methods: {},
    mounted() {
    }
};
</script>

<template>
<div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
        <slot />
    </Vertical>
</div>
</template>
